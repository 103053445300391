import { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

import { useRequest } from './requestContext';
import { useSession } from './userContext';

const defaultContextValue = {
  posts: null,
  getPosts: () => { },
  requestPost: () => { },
};

const PostContext = createContext(defaultContextValue);

export function PostProvider({ children }) {
  const [posts, setPosts] = useState([]);
  const { setHasErrored, setIsLoading } = useRequest();
  let { session } = useSession();

  session = session || Cookies.get('session');

  function getPosts(gottenPosts) {
    setPosts(gottenPosts);
  }
  
  function addPost(post) {
    setPosts([post, ...posts]);
  }
  
  function deletePost(postId) {
    const deleted = posts.filter((post) => post._id !== postId);
    setPosts(deleted);
  }
  
  function updatePost(newPost) {
    const updated = posts.filter((post) => post._id !== newPost._id);
    setPosts([newPost, ...updated]);
  }

  // TODO: As it is similiar to "requestSession" function, I need to refactor this function
  async function requestPost(url, method = 'GET', body) {
    setIsLoading(true);

    const settings = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.token}`
      },
      body: body ? JSON.stringify(body) : undefined,
      method,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, settings);

      if (!response.ok) throw Error(response.statusText);

      const item = await response.json();

      setIsLoading(false);

      switch (settings.method) {
        case "GET":
          if (item) getPosts(item);
          break;
        case "POST":
          if (item) addPost(item);
          break;
        case "DELETE":
          if (item) deletePost(item._id);
          break;
        case "PUT":
          if (item) updatePost(item);
          break;
        default:
          console.warn('Method not allowed or unkonwn');
      }

      return;
    }
    catch (err) {
      console.error(err);
      setIsLoading(false);
      setHasErrored(true);
    }
  }

  const contextValue = {
    posts,
    getPosts,
    requestPost,
  };

  return <PostContext.Provider value={contextValue}>{children}</PostContext.Provider>;
}

export function usePosts() {
  return useContext(PostContext);
}
