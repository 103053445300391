import { createContext, useContext, useState } from 'react';

const defaultContextValue = {
  isLoading: false,
  hasErrored: false,
};

const RequestContext = createContext(defaultContextValue);

export function RequestProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  const contextValue = {
    isLoading,
    hasErrored,
    setIsLoading,
    setHasErrored,
  };

  return <RequestContext.Provider value={contextValue}>{children}</RequestContext.Provider>;
}

export function useRequest() {
  return useContext(RequestContext);
}
