import { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

import { useRequest } from './requestContext';

const defaultContextValue = {
  session: null,
  requestSession: () => { },
  removeSession: () => { },
};

const UserContext = createContext(defaultContextValue);

export function UserProvider({ children }) {
  const [session, setSession] = useState(null);
  const { setHasErrored, setIsLoading } = useRequest();

  const cookieSession = JSON.parse(Cookies.get('session') || 'null');

  function removeSession() {
    Cookies.remove('session');
    setSession(null);
  }

  function updateSession(session) {
    setSession(session);
  }

  // TODO: As it is similiar to "requestPost" function, I need to refactor this function
  async function requestSession(url, method = 'GET', body) {
    setIsLoading(true);

    const settings = {
      headers: {
        'Content-Type': "application/json"
      },
      body: body ? JSON.stringify(body) : undefined,
      method,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, settings);

      if (!response.ok) throw Error(response.statusText);

      const item = await response.json();

      setIsLoading(false);

      switch (settings.method) {
        case "POST":
          if (item) {
            // Cookie valid for 60 minutes (100/24 days)
            Cookies.set('session', JSON.stringify(item), 100 / 24);
            updateSession(item);
          }
          break;
        case "GET":
        case "PUT":
        case "DELETE":
        default:
          console.warn('Method not allowed or unkonwn');
      }

      return;
    }
    catch (err) {
      console.error(err);
      setIsLoading(false);
      setHasErrored(true);
    }
  }

  const contextValue = {
    session: session || cookieSession,
    requestSession,
    removeSession,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useSession() {
  return useContext(UserContext);
}
