import React from 'react';
import { createRoot } from 'react-dom/client';

import { RequestProvider } from './contexts/requestContext';
import { UserProvider } from './contexts/userContext';
import { PostProvider } from './contexts/postContext';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <RequestProvider>
    <UserProvider>
      <PostProvider>
        <App />
      </PostProvider>
    </UserProvider>
  </RequestProvider>,
);

serviceWorker.unregister();
