import React from 'react';

import Loader from './Loader';
import Error from '../layouts/Error';
import { useRequest } from '../contexts/requestContext';

const withRequestStatus = WrappedComponent => () => {
  const { hasErrored, isLoading } = useRequest();

  return (
    <section className='main-section'>
      {isLoading ? <Loader /> : hasErrored ? <Error /> : <WrappedComponent />}
    </section>
  );

}

export default withRequestStatus;
