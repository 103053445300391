import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSession } from '../contexts/userContext';
import withRequestStatus from '../components/withRequestStatus';

const Login = () => {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  let { session, requestSession } = useSession();

  useEffect(() => {
    if (session) navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.token ]);

  const handleLogin = (event) => {
    event.preventDefault();

    requestSession('/users/login', 'POST', {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    });
    return navigate('/')
  };

  return (
    <section className='main-section'>
      <div className='heading main'>
        User Login
      </div>
      <form className='form' onSubmit={handleLogin}>
        <input type='text' placeholder='Username' ref={usernameRef} />
        <input className='tiny-margin-top' type='password' placeholder='Password' ref={passwordRef} />
        <button type='submit' className='button dark'>Submit</button>
      </form>
    </section>
  );
}

export default withRequestStatus(Login);
