import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './layouts/Home';
import Login from './layouts/Login';
import Error from './layouts/Error';

const App = () => {
  return (
    <BrowserRouter>
      <div className='app'>
        <header className='app-header'>
          <Navbar />
        </header>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />

          <Route path='*' element={<Error message='Error: 404' />} />
        </Routes>
      </div>
    </BrowserRouter >
  );
}

export default App;
